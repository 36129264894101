<template>
  <div class="statistics">
    <HeaderMenu/>
    <div class="container py-4">
      <div v-if="data.length > 0">
        <p class="text-center">
          <span class="fw-medium pe-2 d-block d-sm-inline-block"
          >{{ $t("__statisticsDateTimeRange") }} ：</span
          >
          <span class="d-inline-block">{{
              $getTimeZoneDate(start, timezone, 'LLLL')
            }}</span>
          ~
          <span class="d-inline-block">{{
              $getTimeZoneDate(end, timezone, 'LLLL')
            }}</span>
        </p>
        <div class="row g-2 g-sm-4 data-card align-items-stretch justify-content-center">
          <div class="col-6 col-lg-4 col-xl-3 col-xxl-auto">
            <div class="card">
              <div class="card-body">
                <h2 class="h5 fw-medium mb-3">{{ $t("__leaveBedRisk") }}</h2>
                <p v-if="scoreDetails && scoreDetails.leave_score && scoreDetails.leave_score['4'] && scoreDetails.leave_score['4'].length > 0">
                  {{ $t("__highRisk") }} : {{ scoreDetails.leave_score['4'].length }}
                </p>
                <p v-else>{{ $t("__highRisk") }} : 0</p>

                <PieChart
                    :svgPaddingX="svgPaddingX"
                    :svgPaddingY="svgPaddingY"
                    :totalCount="data.length"
                    :highRisk="leaveScoreTotal['4']"
                    :mediumHighRisk="leaveScoreTotal['3']"
                    :mediumLowRisk="leaveScoreTotal['2']"
                    :lowRisk="leaveScoreTotal['1']"
                />
              </div>
              <div class="accordion accordion-flush" id="LVBStatisticsAccordion"
                   v-if="Object.keys(scoreDetails.leave_score).some(function(key) { return Number(key) > 0; })">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="LVBStatistics_header">
                    <a class="accordion-button collapsed" type="button" @click="toggleAdditionalInfo('leaveBed')">
                      {{ $t('__information') }}
                    </a>
                  </h2>
                  <div class="accordion-collapse collapse show">
                    <div class="accordion-body p-0">
                      <ul class="list-group list-group-flush">
                        <li v-if="scoreDetails && scoreDetails.leave_score && scoreDetails.leave_score['4'] && scoreDetails.leave_score['4'].length > 0"
                            class="list-group-item fw-bold">
                          <span style="color: #FD6161">{{ $t('__highRisk') }}</span>
                          <span>{{ $t('__leaveBedRiskValue') }}</span>
                        </li>
                        <template
                            v-if="scoreDetails && scoreDetails.leave_score && scoreDetails.leave_score['4'] && scoreDetails.leave_score['4'].length > 0">
                          <li v-for="(item, index) in scoreDetails.leave_score['4']"
                              :key="'leave_highRisk' + index" class="list-group-item">
                            <span>
                            {{ item.resident_name }}
                            </span>
                            <span>
                              {{ (parseFloat(item.asleep_leave)).toFixed(1) }}
                            </span>
                          </li>
                        </template>

                        <li v-if="scoreDetails && scoreDetails.leave_score && scoreDetails.leave_score['3'] && scoreDetails.leave_score['3'].length > 0"
                            class="list-group-item fw-bold">
                          <span style="color: #FFBC3A">{{ $t('__highMediumRisk') }}</span>
                          <span>{{ $t('__leaveBedRiskValue') }}</span>
                        </li>
                        <template
                            v-if="scoreDetails && scoreDetails.leave_score && scoreDetails.leave_score['3'] && scoreDetails.leave_score['3'].length > 0">
                          <li v-for="(item, index) in scoreDetails.leave_score['3']"
                              :key="'leave_highMediumRisk' + index" class="list-group-item">
                            <span>
                            {{ item.resident_name }}
                            </span>
                            <span>
                              {{ (parseFloat(item.asleep_leave)).toFixed(1) }}
                            </span>
                          </li>
                        </template>

                        <template v-if="showLeaveBedAdditionalInfo">
                          <li v-if="scoreDetails && scoreDetails.leave_score && scoreDetails.leave_score['2'] && scoreDetails.leave_score['2'].length > 0"
                              class="list-group-item fw-bold">
                            <span style="color: #BBCB3F">{{ $t('__lowMediumRisk') }}</span>
                            <span>{{ $t('__leaveBedRiskValue') }}</span>
                          </li>
                          <template
                              v-if="scoreDetails && scoreDetails.leave_score && scoreDetails.leave_score['2'] && scoreDetails.leave_score['2'].length > 0">
                            <li v-for="(item, index) in scoreDetails.leave_score['2']"
                                :key="'leave_lowMediumRisk' + index" class="list-group-item">
                              <span>
                              {{ item.resident_name }}
                              </span>
                              <span>
                                {{ (parseFloat(item.asleep_leave)).toFixed(1) }}
                              </span>
                            </li>
                          </template>

                          <li v-if="scoreDetails && scoreDetails.leave_score && scoreDetails.leave_score['1'] && scoreDetails.leave_score['1'].length > 0"
                              class="list-group-item fw-bold">
                            <span style="color: #07CD32">{{ $t('__lowRisk') }}</span>
                            <span>{{ $t('__leaveBedRiskValue') }}</span>
                          </li>
                          <template
                              v-if="scoreDetails && scoreDetails.leave_score && scoreDetails.leave_score['1'] && scoreDetails.leave_score['1'].length > 0">
                            <li v-for="(item, index) in scoreDetails.leave_score['1']"
                                :key="'leave_lowRisk' + index" class="list-group-item">
                              <span>
                              {{ item.resident_name }}
                              </span>
                              <span>
                                {{ (parseFloat(item.asleep_leave)).toFixed(1) }}
                              </span>
                            </li>
                          </template>
                        </template>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6 col-lg-4 col-xl-3 col-xxl-auto">
            <div class="card">
              <div class="card-body">
                <h2 class="h5 fw-medium mb-3">{{ $t("__sleepRisk") }}</h2>
                <p v-if="scoreDetails && scoreDetails.sleep_score && scoreDetails.sleep_score['4'] && scoreDetails.sleep_score['4'].length > 0">
                  {{ $t("__highRisk") }} : {{ scoreDetails.sleep_score['4'].length }}
                </p>
                <p v-else>{{ $t("__highRisk") }} : 0</p>
                <PieChart
                    :svgPaddingX="svgPaddingX"
                    :svgPaddingY="svgPaddingY"
                    :totalCount="data.length"
                    :highRisk="sleepScoreTotal['4']"
                    :mediumHighRisk="sleepScoreTotal['3']"
                    :mediumLowRisk="sleepScoreTotal['2']"
                    :lowRisk="sleepScoreTotal['1']"
                />
              </div>
              <div class="accordion accordion-flush" id="LYBStatisticsAccordion"
                   v-if="Object.keys(scoreDetails.sleep_score).some(function(key) { return Number(key) > 0; })">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="LYBStatistics_header">
                    <a class="accordion-button collapsed" type="button" @click="toggleAdditionalInfo('sleep')">
                      {{ $t('__information') }}
                    </a>
                  </h2>
                  <div id="flush-LYBStatistics" class="accordion-collapse collapse show"
                       aria-labelledby="LYBStatistics_header" data-bs-parent="#LYBStatisticsAccordion">
                    <div class="accordion-body p-0">
                      <ul class="list-group list-group-flush">
                        <li v-if="scoreDetails && scoreDetails.respiration_score && scoreDetails.sleep_score['4'] && scoreDetails.sleep_score['4'].length > 0"
                            class="list-group-item fw-bold">
                          <span style="color: #FD6161">{{ $t('__highRisk') }}</span>
                          <span>{{ $t('__sleepRiskValue') }}</span>
                        </li>
                        <template
                            v-if="scoreDetails && scoreDetails.sleep_score && scoreDetails.sleep_score['4'] && scoreDetails.sleep_score['4'].length > 0">
                          <li v-for="(item, index) in scoreDetails.sleep_score['4']"
                              :key="'sleep_highRisk' + index" class="list-group-item">
                            <span>
                            {{ item.resident_name }}
                            </span>
                            <span>
                              {{ (parseFloat(item.night_sleep)).toFixed(1) }}/{{ parseFloat(item.sleep_ratio).toFixed(0) }}%
                            </span>
                          </li>
                        </template>

                        <li v-if="scoreDetails && scoreDetails.sleep_score && scoreDetails.sleep_score['3'] && scoreDetails.sleep_score['3'].length > 0"
                            class="list-group-item fw-bold">
                          <span style="color: #FFBC3A">{{ $t('__highMediumRisk') }}</span>
                          <span>{{ $t('__sleepRiskValue') }}</span>
                        </li>
                        <template
                            v-if="scoreDetails && scoreDetails.sleep_score && scoreDetails.sleep_score['3'] && scoreDetails.sleep_score['3'].length > 0">
                          <li v-for="(item, index) in scoreDetails.sleep_score['3']"
                              :key="'sleep_highMediumRisk' + index" class="list-group-item">
                            <span>
                            {{ item.resident_name }}
                            </span>
                            <span>
                              {{ (parseFloat(item.night_sleep)).toFixed(1) }}/{{ parseFloat(item.sleep_ratio).toFixed(0) }}%
                            </span>
                          </li>
                        </template>

                        <template v-if="showSleepAdditionalInfo">
                          <li v-if="scoreDetails && scoreDetails.sleep_score && scoreDetails.sleep_score['2'] && scoreDetails.sleep_score['2'].length > 0"
                              class="list-group-item fw-bold">
                            <span style="color: #BBCB3F">{{ $t('__lowMediumRisk') }}</span>
                            <span>{{ $t('__sleepRiskValue') }}</span>
                          </li>
                          <template
                              v-if="scoreDetails && scoreDetails.sleep_score && scoreDetails.sleep_score['2'] && scoreDetails.sleep_score['2'].length > 0">
                            <li v-for="(item, index) in scoreDetails.sleep_score['2']"
                                :key="'sleep_lowMediumRisk' + index" class="list-group-item">
                              <span>
                              {{ item.resident_name }}
                              </span>
                              <span>
                                {{ (parseFloat(item.night_sleep)).toFixed(1) }}/{{ parseFloat(item.sleep_ratio).toFixed(0) }}%
                              </span>
                            </li>
                          </template>

                          <li v-if="scoreDetails && scoreDetails.sleep_score && scoreDetails.sleep_score['1'] && scoreDetails.sleep_score['1'].length > 0"
                              class="list-group-item fw-bold">
                            <span style="color: #07CD32">{{ $t('__lowRisk') }}</span>
                            <span>{{ $t('__sleepRiskValue') }}</span>
                          </li>
                          <template
                              v-if="scoreDetails && scoreDetails.sleep_score && scoreDetails.sleep_score['1'] && scoreDetails.sleep_score['1'].length > 0">
                            <li v-for="(item, index) in scoreDetails.sleep_score['1']"
                                :key="'sleep_lowRisk' + index" class="list-group-item">
                              <span>
                              {{ item.resident_name }}
                              </span>
                              <span>
                                {{ (parseFloat(item.night_sleep)).toFixed(1) }}/{{ parseFloat(item.sleep_ratio).toFixed(0) }}%
                              </span>
                            </li>
                          </template>
                        </template>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6 col-lg-4 col-xl-3 col-xxl-auto">
            <div class="card">
              <div class="card-body">
                <h2 class="h5 fw-medium mb-3">{{ $t("__respirationRisk") }}</h2>
                <p v-if="scoreDetails && scoreDetails.respiration_score && scoreDetails.respiration_score['4'] && scoreDetails.respiration_score['4'].length > 0">
                  {{ $t("__highRisk") }} : {{ scoreDetails.respiration_score['4'].length }}
                </p>
                <p v-else>{{ $t("__highRisk") }} : 0</p>
                <PieChart
                    :svgPaddingX="svgPaddingX"
                    :svgPaddingY="svgPaddingY"
                    :totalCount="data.length"
                    :highRisk="respirationScoreTotal['4']"
                    :mediumHighRisk="respirationScoreTotal['3']"
                    :mediumLowRisk="respirationScoreTotal['2']"
                    :lowRisk="respirationScoreTotal['1']"
                />
              </div>
              <div class="accordion accordion-flush" id="sleepStatisticsAccordion"
                   v-if="Object.keys(scoreDetails.respiration_score).some(function(key) { return Number(key) > 0; })">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="sleepStatistics_header">
                    <a class="accordion-button collapsed" type="button" @click="toggleAdditionalInfo('respiration')">
                      {{ $t('__information') }}
                    </a>
                  </h2>
                  <div id="flush-sleepStatistics" class="accordion-collapse collapse show"
                       aria-labelledby="sleepStatistics_header" data-bs-parent="#sleepStatisticsAccordion">
                    <div class="accordion-body p-0">
                      <ul class="list-group list-group-flush">
                        <li v-if="scoreDetails && scoreDetails.respiration_score && scoreDetails.respiration_score['4'] && scoreDetails.respiration_score['4'].length > 0"
                            class="list-group-item fw-bold">
                          <span style="color: #FD6161">{{ $t('__highRisk') }}</span>
                          <span>{{ $t('__respirationStandardDeviateRisk') }}</span>
                        </li>
                        <template
                            v-if="scoreDetails && scoreDetails.respiration_score && scoreDetails.respiration_score['4'] && scoreDetails.respiration_score['4'].length > 0">
                          <li v-for="(item, index) in scoreDetails.respiration_score['4']"
                              :key="'respiration_highRisk' + index" class="list-group-item">
                            <span>
                            {{ item.resident_name }}
                            </span>
                            <span>
                              {{ item.sleep_respiration }}/{{ item.std_dev }}
                            </span>
                          </li>
                        </template>

                        <li v-if="scoreDetails && scoreDetails.respiration_score && scoreDetails.respiration_score['3'] && scoreDetails.respiration_score['3'].length > 0"
                            class="list-group-item fw-bold">
                          <span style="color: #FFBC3A">{{ $t('__highMediumRisk') }}</span>
                          <span>{{ $t('__respirationStandardDeviateRisk') }}</span>
                        </li>
                        <template
                            v-if="scoreDetails && scoreDetails.respiration_score && scoreDetails.respiration_score['3'] && scoreDetails.respiration_score['3'].length > 0">
                          <li v-for="(item, index) in scoreDetails.respiration_score['3']"
                              :key="'respiration_highMediumRisk' + index" class="list-group-item">
                            <span>
                            {{ item.resident_name }}
                            </span>
                            <span>
                              {{ item.sleep_respiration }}/{{ item.std_dev }}
                            </span>
                          </li>
                        </template>

                        <template v-if="showRespirationAdditionalInfo">
                          <li v-if="scoreDetails && scoreDetails.respiration_score && scoreDetails.respiration_score['2'] && scoreDetails.respiration_score['2'].length > 0"
                              class="list-group-item fw-bold">
                            <span style="color: #BBCB3F">{{ $t('__lowMediumRisk') }}</span>
                            <span>{{ $t('__respirationStandardDeviateRisk') }}</span>
                          </li>
                          <template
                              v-if="scoreDetails && scoreDetails.respiration_score && scoreDetails.respiration_score['2'] && scoreDetails.respiration_score['2'].length > 0">
                            <li v-for="(item, index) in scoreDetails.respiration_score['2']"
                                :key="'respiration_lowMediumRisk' + index" class="list-group-item">
                              <span>
                              {{ item.resident_name }}
                              </span>
                              <span>
                                {{ item.sleep_respiration }}/{{ item.std_dev }}
                              </span>
                            </li>
                          </template>

                          <li v-if="scoreDetails && scoreDetails.respiration_score && scoreDetails.respiration_score['1'] && scoreDetails.respiration_score['1'].length > 0"
                              class="list-group-item fw-bold">
                            <span style="color: #07CD32">{{ $t('__lowRisk') }}</span>
                            <span>{{ $t('__respirationStandardDeviateRisk') }}</span>
                          </li>
                          <template
                              v-if="scoreDetails && scoreDetails.respiration_score && scoreDetails.respiration_score['1'] && scoreDetails.respiration_score['1'].length > 0">
                            <li v-for="(item, index) in scoreDetails.respiration_score['1']"
                                :key="'respiration_lowRisk' + index" class="list-group-item">
                              <span>
                              {{ item.resident_name }}
                              </span>
                              <span>
                                {{ item.sleep_respiration }}/{{ item.std_dev }}
                              </span>
                            </li>
                          </template>
                        </template>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6 col-lg-4 col-xl-3 col-xxl-auto">
            <div class="card">
              <div class="card-body">
                <h2 class="h5 fw-medium mb-3">
                  {{ $t("__lyingRisk") }}
                </h2>
                <p v-if="scoreDetails && scoreDetails.lying_score && scoreDetails.lying_score['4'] && scoreDetails.lying_score['4'].length > 0">
                  {{ $t("__highRisk") }} : {{ scoreDetails.lying_score['4'].length }}
                </p>
                <p v-else>{{ $t("__highRisk") }} : 0</p>
                <PieChart
                    :svgPaddingX="svgPaddingX"
                    :svgPaddingY="svgPaddingY"
                    :totalCount="data.length"
                    :highRisk="lyingScoreTotal['4']"
                    :mediumHighRisk="lyingScoreTotal['3']"
                    :mediumLowRisk="lyingScoreTotal['2']"
                    :lowRisk="lyingScoreTotal['1']"
                />
              </div>
              <div class="accordion accordion-flush" id="RRNotifyStatisticsAccordion"
                   v-if="Object.keys(scoreDetails.lying_score).some(function(key) { return Number(key) > 0; })">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="RRNotifyStatistics_header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#flush-RRNotifyStatistics" aria-expanded="false"
                            aria-controls="flush-RRNotifyStatistics">
                      {{ $t('__information') }}
                    </button>
                  </h2>
                  <div id="flush-RRNotifyStatistics" class="accordion-collapse collapse"
                       aria-labelledby="RRNotifyStatistics_header" data-bs-parent="#RRNotifyStatisticsAccordion">
                    <div class="accordion-body p-0">
                      <ul class="list-group list-group-flush">
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-else class="text-center mb-3">
        <h3>{{ $t('__reportExportingNoteInfo') }}</h3>
      </div>

    </div>

    <div class="container-fluid py-4">
      <div class="card">
        <div class="card-body">
          <form style="margin-bottom: 20px;" @submit.prevent="getWeeklyReport">
            <div class="mb-3">
              <label for="bedNo" class="form-label fw-bold">{{ $t("__residentName") }}</label>
              <div class="col-sm-6 col-md-4">
                <select class="form-select" v-model="resident_id">
                  <option :value="null">{{ $t('__openThisSelectMenuResident') }}</option>
                  <template v-for="data in resident">
                    <option :key="data.id" :value="data.id" v-if="data.name">
                      {{ ((data.device === null) ? '' : data.device.bed_number) + " / " + data.name }}
                    </option>
                  </template>
                </select>
              </div>
            </div>

            <div class="mb-3">
              <label class="form-label fw-bold">{{ $t('__date') }}</label>
              <div class="d-flex align-items-center flex-wrap">
                <div class="d-inline-block pe-sm-4 mb-2">
                  <VueCtkDateTimePicker
                      :label="$t('__startDate')"
                      v-model="start_date"
                      only-date
                      format="YYYY-MM-DD"
                      formatted="YYYY-MM-DD"
                      color="#2CBDC0"
                      button-color="#2CBDC0"
                      :max-date="end_date"
                  />
                  <div class="text-danger" v-if="is_invalid.start_date">
                    {{ feedback_message.start_date }}
                  </div>
                </div>
                <div class="d-inline-block pe-sm-4 mb-2">
                  <VueCtkDateTimePicker
                      :label="$t('__endDate')"
                      v-model="end_date"
                      only-date
                      format="YYYY-MM-DD"
                      formatted="YYYY-MM-DD"
                      color="#2CBDC0"
                      button-color="#2CBDC0"
                      :min-date="start_date"
                  />
                  <div class="text-danger" v-if="is_invalid.end_date">
                    {{ feedback_message.end_date }}
                  </div>
                </div>
              </div>
            </div>

            <button type="submit" class="btn btn-primary rounded-pill">
              {{ $t("__reportResidentRiskData") }}
            </button>

            <button v-if="data.length > 0" style="margin-left: 10px;" @click="exportToCSV"
                    class="btn btn-primary rounded-pill">
              {{ $t("__exportCSV") }}
            </button>
          </form>

          <div v-if="data.length === 0 && displayDailyReport === true">
            {{ $t('__noDate') }}
          </div>

          <b-table
              v-if="data.length > 0"
              responsive
              striped
              borderless
              head-variant="light"
              :empty-text="$t('__notData')"
              :empty-filtered-text="$t('__notFindDevice')"
              :items="data"
              :fields="reportFields"
          >
            <template v-slot:cell(resident_name)="data">
              <span v-if="data.item.resident">
                {{ data.item.resident.name }}
              </span>
            </template>

            <template v-slot:cell(day_leave)="data">
              {{ (parseFloat(data.item.day_leave)).toFixed(1) }}
            </template>

            <template v-slot:cell(asleep_leave)="data">
              {{ (parseFloat(data.item.asleep_leave)).toFixed(1) }}
            </template>

            <template v-slot:cell(all_on_bed)="data">
              {{ (parseFloat(data.item.all_on_bed)).toFixed(1) }}
            </template>

            <template v-slot:cell(night_on_bed)="data">
              {{ (parseFloat(data.item.night_on_bed)).toFixed(1) }}
            </template>

            <template v-slot:cell(night_sleep)="data">
              {{ (parseFloat(data.item.night_sleep)).toFixed(1) }}
            </template>

            <template v-slot:cell(rest_efficiency)="data">
              <span v-if="data.item && data.item.rest_efficiency">
                {{ (parseFloat(data.item.rest_efficiency)).toFixed(0) }}%
              </span>
              <span v-else>
                0%
              </span>
            </template>

            <template v-slot:cell(sleep_respiration)="data">
              <span v-if="data.item && data.item.sleep_respiration > 0">
                {{ (parseFloat(data.item.sleep_respiration)).toFixed(1) }}
              </span>
              <span v-else>
                0
              </span>
            </template>

            <template v-slot:cell(std_dev)="data">
              <span v-if="data.item">
                {{ (parseFloat(data.item.std_dev)).toFixed(1) }}
              </span>
              <span v-else>
                0
              </span>
            </template>

            <template v-slot:cell(respiration_max)="data">
              <span v-if="data.item">
                {{ (parseFloat(data.item.respiration_max)).toFixed(1) }}
              </span>
              <span v-else>
                0
              </span>
            </template>

            <template v-slot:cell(respiration_min)="data">
              <span v-if="data.item">
                {{ (parseFloat(data.item.respiration_min)).toFixed(1) }}
              </span>
              <span v-else>
                0
              </span>
            </template>
          </b-table>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import HeaderMenu from '@/components/layout/HeaderMenu.vue';
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import PieChart from '@/components/chart/NewPieChart.vue';
import i18n from '@/lang/lang.js';
import { report } from '@/http/api/report.js';
import { mapMutations, mapState } from 'vuex';
import { resident } from '@/http/api/resident';

export default {
    name: 'ReportDailyRiskSummary',
    components: { HeaderMenu, PieChart, VueCtkDateTimePicker },
    data() {
        return {
            lang: i18n.locale,
            data: [],
            svgPaddingX: 20,
            svgPaddingY: 20,
            bootstrap_modal: null,
            is_reportSetting_invalid: false,
            reportSetting_feedback_message: i18n.t('__reportSettingIsRequired'),
            start_date: '',
            end_date: '',
            resident_id: null,
            scoreDetails: {},
            start: '',
            end: '',
            reportFields: [
                {
                    key: 'week_start',
                    label: i18n.t('__measurementStartDate'),
                    class: 'align-middle',
                    sortable: true
                },
                {
                    key: 'resident_name',
                    label: i18n.t('__residentName'),
                    class: 'align-middle',
                    // thStyle: 'width:120px',
                    sortable: true
                },
                {
                    key: 'day_leave',
                    label: i18n.t('__dayLeaveBed'),
                    class: 'align-middle',
                    sortable: true
                },
                {
                    key: 'asleep_leave',
                    label: i18n.t('__numberOfGettingOutOfBedAtNight'),
                    class: 'align-middle',
                    sortable: true
                },
                {
                    key: 'all_on_bed',
                    label: i18n.t('__allDayOnBed'),
                    class: 'align-middle',
                    sortable: true
                },
                {
                    key: 'night_on_bed',
                    label: i18n.t('__nightOnBedHour'),
                    class: 'align-middle',
                    sortable: true
                },
                {
                    key: 'night_sleep',
                    label: i18n.t('__nightRestHour'),
                    class: 'align-middle',
                    sortable: true
                },
                {
                    key: 'rest_efficiency',
                    label: i18n.t('__restRate'),
                    class: 'align-middle',
                    sortable: true
                },
                {
                    key: 'sleep_respiration',
                    label: i18n.t('__sleepBreathingRateTitle'),
                    class: 'align-middle',
                    sortable: true
                },
                {
                    key: 'std_dev',
                    label: i18n.t('__respirationStandardDeviate'),
                    class: 'align-middle',
                    sortable: true
                },
                {
                    key: 'respiration_max',
                    label: i18n.t('__respirationMaxRate'),
                    class: 'align-middle',
                    sortable: true
                },
                {
                    key: 'respiration_min',
                    label: i18n.t('__respirationMinRate'),
                    class: 'align-middle',
                    sortable: true
                },
                {
                    key: 'daily_reports_count',
                    label: i18n.t('__weekDays'),
                    class: 'align-middle',
                    sortable: true
                }
            ],
            is_invalid: {
                start_date: false,
                end_date: false
            },
            displayDailyReport: false,
            resident: [],
            disabledDates: [],
            showLeaveBedAdditionalInfo: false,
            showSleepAdditionalInfo: false,
            showRespirationAdditionalInfo: false,
            showLyingAdditionalInfo: false
        };
    },
    computed: {
        ...mapState(['token', 'hasStatistics', 'timezone']),
        ended_at() {
            const vm = this;
            return vm.$getTimeZoneDate(new Date(), vm.timezone, 'YYYY-MM-DD');
        },
        leaveScoreTotal() {
            if (!this.scoreDetails || !this.scoreDetails.leave_score) {
                return { 4: 0, 3: 0, 2: 0, 1: 0 };
            }

            const scores = ['4', '3', '2', '1'];

            return scores.reduce((acc, score) => {
                acc[score] = (this.scoreDetails.leave_score[score] || []).length;
                return acc;
            }, {});
        },
        sleepScoreTotal() {
            if (!this.scoreDetails || !this.scoreDetails.sleep_score) {
                return { 4: 0, 3: 0, 2: 0, 1: 0 };
            }

            const scores = ['4', '3', '2', '1'];

            return scores.reduce((acc, score) => {
                acc[score] = (this.scoreDetails.sleep_score[score] || []).length;
                return acc;
            }, {});
        },
        respirationScoreTotal() {
            if (!this.scoreDetails || !this.scoreDetails.respiration_score) {
                return { 4: 0, 3: 0, 2: 0, 1: 0 };
            }

            const scores = ['4', '3', '2', '1'];

            return scores.reduce((acc, score) => {
                acc[score] = (this.scoreDetails.respiration_score[score] || []).length;
                return acc;
            }, {});
        },
        lyingScoreTotal() {
            if (!this.scoreDetails || !this.scoreDetails.lying_score) {
                return { 4: 0, 3: 0, 2: 0, 1: 0 };
            }

            const scores = ['4', '3', '2', '1'];

            return scores.reduce((acc, score) => {
                acc[score] = (this.scoreDetails.lying_score[score] || []).length;
                return acc;
            }, {});
        }
    },
    watch: {
        token: {
            handler: function() {
                this.getWeeklyReport();
                this.getResident();
            },
            deep: true
        }
    },
    methods: {
        ...mapMutations([
            'LoadingText',
            'Loading',
            'Loaded',
            'AlertsInfo',
            'Alerted'
        ]),
        getWeeklyReport() {
            const vm = this;
            var config = Object.assign({}, vm.token);
            vm.Loading();
            config.params = {};

            if (!vm.start_date) {
                // vm.start_date = this.getInitialDate();
            }
            if (!vm.end_date) {
                // vm.end_date = this.getInitialDate();
            }

            report.getWeeklyReport(vm.token, {
                start_date: vm.start_date,
                end_date: vm.end_date,
                resident_id: vm.resident_id
            }).then((res) => {
                if (res.status <= 201 && res.data.status === 'success') {
                    vm.data = res.data.data.length > 0 ? res.data.data : [];
                    vm.scoreDetails = res.data.score_details;
                    vm.start = res.data.start;
                    vm.end = res.data.end;
                } else {
                    vm.AlertsInfo({
                        state: 'error',
                        title: i18n.t('__error'),
                        info: res.data.errors.toString()
                    });
                    vm.Alerted();
                }
                vm.Loaded();
            });
        },
        showStatisticalConditionsSet() {
            const vm = this;
            vm.$store.dispatch('getStatistics').then(() => {
                // if (vm.hasStatistics) {
                // }
            });
        },
        getResident() {
            const vm = this;
            resident.get('', vm.token).then((res) => {
                if (res.status <= 201 && res.data.status === 'success') {
                    const data = res.data.data;
                    vm.resident = data;
                } else {
                    vm.AlertsInfo({
                        state: 'error',
                        title: i18n.t('__error'),
                        info: res.data.errors.toString()
                    });
                    vm.Alerted();
                }
            });
        },
        exportToCSV() {
            const headers = [i18n.t('__resident'), ...this.reportFields.map(field => `"${this.$t(field.label)}"`)].join(',') + '\n';

            const rows = this.data.map(item => {
                const residentId = item.resident ? item.resident.id : '';
                const residentName = item.resident ? item.resident.name : '';
                const residentInfo = `${residentName} / ${residentId}`;
                const row = [`"${residentInfo.replace(/"/g, '""')}"`];

                row.push(...this.reportFields.map(field => {
                    const key = field.key;
                    let value;

                    if (key === 'rest_efficiency') {
                        value = (item.night_sleep > 0 && item.night_on_bed > 0)
                            ? ((item.night_sleep / item.night_on_bed) * 100).toFixed(2) + '%' : '0%';
                    } else if (key === 'std_dev') {
                        value = item.respiration_analy && JSON.parse(item.respiration_analy).std_dev
                            ? JSON.parse(item.respiration_analy).std_dev.toFixed(2) : '0';
                    } else if (key === 'respiration_max') {
                        value = item.respiration_analy && JSON.parse(item.respiration_analy).max
                            ? JSON.parse(item.respiration_analy).max.toFixed(2) : '0';
                    } else if (key === 'respiration_min') {
                        value = item.respiration_analy && JSON.parse(item.respiration_analy).min
                            ? JSON.parse(item.respiration_analy).min.toFixed(2) : '0';
                    } else {
                        value = key.split('.').reduce((o, i) => (o ? o[i] : ''), item);
                    }

                    if (value === null || value === undefined) {
                        return '';
                    }
                    return `"${value.toString().replace(/"/g, '""')}"`;
                }));

                return row.join(',');
            }).join('\n');

            const csvContent = headers + rows;
            const bom = '\uFEFF';

            const blob = new Blob([bom + csvContent], { type: 'text/csv;charset=utf-8;' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.setAttribute('download', 'report_data.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        setReportDates() {
            const now = new Date();

            const currentWeekMonday = new Date(now);
            const dayOfWeek = now.getDay();
            const diffToMonday = (dayOfWeek + 6) % 7;
            currentWeekMonday.setDate(now.getDate() - diffToMonday);

            const lastWeekMonday = new Date(currentWeekMonday);
            lastWeekMonday.setDate(currentWeekMonday.getDate() - 7);

            const lastWeekSunday = new Date(lastWeekMonday);
            lastWeekSunday.setDate(lastWeekMonday.getDate() + 6);

            this.start_date = this.formatDate(lastWeekMonday);
            this.end_date = this.formatDate(lastWeekSunday);
        },
        formatDate(date) {
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            return `${year}-${month}-${day}`;
        },
        toggleAdditionalInfo(type) {
            switch (type) {
            case 'leaveBed':
                this.showLeaveBedAdditionalInfo = !this.showLeaveBedAdditionalInfo;
                break;
            case 'sleep':
                this.showSleepAdditionalInfo = !this.showSleepAdditionalInfo;
                break;
            case 'respiration':
                this.showRespirationAdditionalInfo = !this.showRespirationAdditionalInfo;
                break;
            case 'lying':
                this.showLyingAdditionalInfo = !this.showLyingAdditionalInfo;
                break;
            default:
            }
        }
    },
    created() {
        const vm = this;
        vm.setReportDates();
    },
    mounted() {
        const vm = this;
        if (vm.token.headers.Authorization) {
            vm.getWeeklyReport();
            vm.getResident();
        }
        vm.$nextTick(function() {
            // vm.bootstrap_modal = new Modal(vm.$refs.statisticalConditionsModal);
            // vm.$refs.statisticalConditionsModal.addEventListener('show.bs.modal', () => {
            // });
        });
    }
};
</script>

<style lang="scss">
.list-group-item {
  display: flex;
  justify-content: space-between;
}

.data-card {
  > div {
    @include media-breakpoint-up(xxl) {
      width: (100% / 5);
    }
  }
}

.modal-custom-size-zh {
  max-width: 530px;

  .left-col {
    @include media-breakpoint-up(md) {
      width: 140px;
    }
  }

  .right-col {
    @include media-breakpoint-up(md) {
      white-space: nowrap !important;
    }
  }
}

.modal-custom-size-en {
  max-width: 670px;

  .left-col {
    @include media-breakpoint-up(md) {
      width: 200px;
      text-align: center;
    }

    button {
      margin: auto;
    }
  }

  .right-col {
    @include media-breakpoint-up(md) {
      white-space: nowrap !important;
    }
  }
}
</style>
